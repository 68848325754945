<script>
import store from "@/state/store";
var state = store._modules.root._children.auth.state;

export default {
  data() {
    return {
      deductions: [],
      emps: [],
      emp: {},
      limit: 20,
      tot_pages: 0,
      page: 1,
      SearchMode: false,
      searchModel: "",
      state: state,
      // appprices:[]
      addAsset: [{}],
      currentAssets: [],
    };
  },
  methods: {
    addDeduction() {
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "emp_id",
            type: "select",
            options: (() => {
              return this.emps.map((emp) => {
                return { value: emp.id, label: emp.name };
              });
            })(),
            label: "salary_deduction.emp_name",
          },
          {
            model: "total_balance",
            type: "text",
            label: "salary_deduction.value",
          },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("salary-deduction", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SearchMode = true;
      this.http
        .post("salary-deduction-details/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.deductions = res.data;
        });
    },
    cancelSearchMode() {
      this.searchModel = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getEmps() {
      this.http.post("emps/branch", {}).then((res) => {
        this.emps = res.data;
      });
    },
    getCashAccount() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.currentAssets = res.data;
      });
    },
    deleteOption(index) {
      this.addAsset.splice(index, 1);
    },
    addEmpLoan() {
      console.log("**********####", this.state);

      this.http
        .post("emps/loans", {
          emp_id: this.emp?.id,
          accounts: this.addAsset,
        })
        .then(() => {
          this.addAsset = [{}];
          this.emp = {};
          this.get(this.page);
        });
    },
    get(page) {
      console.log(page);
      this.http
        .post("emps/loans/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.deductions = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getEmps();
    this.getCashAccount();
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="EmpFilesSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table
          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
          class="table table-striped table-hover mb-0"
        >
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">#</th>
              <th scope="col">{{ $t("emps.code") }}</th>
              <th scope="col">{{ $t("emps.name") }}</th>
              <th scope="col">{{ $t("emp_loans.total_balance") }}</th>
              <th scope="col">{{ $t("emp_loans.paid_balance") }}</th>
              <th scope="col">{{ $t("emp_loans.created") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app, index) in deductions" :key="app" class="">
              <td>{{ index + 1 }}</td>
              <td>{{ app?.emp?.code }}</td>
              <td>{{ app?.emp?.name }}</td>
              <td>{{ parseFloat(app?.total_balance).toLocaleString() }}</td>
              <td>{{ parseFloat(app?.paid_balance).toLocaleString() }}</td>
              <td>{{ app.created.split("T")[0] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->
  <!--end-->

  <!--Start Add Modal-->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addEmpLoan()">
          <div class="modal-header">
            <div class="d-flex justify-content-arround">
              <!-- <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.pay") }}
              </h5> -->
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emp_loans.title") }}
              </h5>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row purchase-container">
              <div class="col">
                <label for="asset_id">{{ $t("emps.name") }}</label>
                <select v-model="emp" class="form-select mb-2">
                  <option v-for="emp in emps" :key="emp" :value="emp">
                    {{ emp?.name }}
                  </option>
                </select>
              </div>
              <div class="col"></div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="asset_id">{{
                  $t("emp_loans.loans_options")
                }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  @click="addAsset.push({})"
                  class="btn float-end"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div v-for="(asset, index) in addAsset" :key="asset">
              <div class="row my-3">
                <div class="d-flex justify-content-between">
                  <select
                    v-model="asset.account_id"
                    class="form-select mb-2"
                    id="asset_id"
                    :placeholder="$t('emps.account')"
                  >
                    <option
                      v-for="assets in currentAssets"
                      :key="assets"
                      :value="assets?.id"
                    >
                      {{
                        $i18n.locale == "ar" ? assets?.name : assets?.name_en
                      }}
                    </option>
                  </select>
                  <input
                    v-model="asset.total_balance"
                    type="number"
                    class="form-control mb-2 mx-1"
                    :placeholder="$t('emps.amount')"
                  />
                  <span class="mx-2">
                    <button
                      class="btn btn-danger"
                      @click="deleteOption(index)"
                      v-if="index > 0"
                    >
                      <i class="bx bx-trash"></i>
                    </button>
                    <button v-else disabled class="btn btn-danger">
                      <i class="bx bx-trash"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              :disabled="
                !addAsset[0]?.hasOwnProperty('account_id') &&
                !addAsset[0]?.hasOwnProperty('value')
              "
              data-bs-dismiss="modal"
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--   Purchases  Pagination     -->
  <ul class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page - 1)"
        aria-label="Previous"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li
      class="page-item"
      :class="{ active: p == page }"
      v-for="p in tot_pages"
      :key="p"
    >
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == tot_pages }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page + 1)"
        aria-label="Next"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  font-size: 15px;
}
</style>
